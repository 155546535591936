/* You can add global styles to this file, and also import other style files */

@import '~animate.css';
@import '../../../assets/fonts/space-mono';
@import '../../../assets/fonts/zen-dots';
@import '../../../styles/warring-galaxies';
@import '~normalize.css/normalize.css';

* {
  user-select: none;
}
