
button {
  cursor: pointer;
  border: none;

  background: @Slate;

  &:hover {
    background: @Space;
  }
}

button.primary {
  font-size: 2rem;
  padding: 16px 32px;

  border-radius: 0.3rem;

  border: none;
  box-shadow: 0 0 2px white;

  cursor: pointer;

  text-transform: uppercase;
}
