@icomoon-font-family: "wg";
@icomoon-font-path: "fonts";

@wg-acc_regen_flexible_plating: "\e900";
@wg-acc_regen_radiative_reconstructor: "\e901";
@wg-acc_regen_recharge_battery: "\e902";
@wg-acc_regen_regenerator: "\e903";
@wg-accessory_part: "\e904";
@wg-action_defend: "\e905";
@wg-action_skip: "\e906";
@wg-armor_part: "\e907";
@wg-artifact_fragment: "\e908";
@wg-charge_boost_I: "\e909";
@wg-charge_boost_II: "\e90a";
@wg-combination_catalyst_t0: "\e90b";
@wg-combination_catalyst_t1: "\e90c";
@wg-energy_attack: "\e90d";
@wg-energy_beam_sweep_I: "\e90e";
@wg-energy_concentrated_beam_I: "\e90f";
@wg-energy_idle: "\e910";
@wg-energy_incinerate_I: "\e911";
@wg-energy_laser_bloodhunterpiercer: "\e912";
@wg-energy_laser_gamma_ray: "\e913";
@wg-energy_laser_gatling_laser: "\e914";
@wg-energy_laser_laser: "\e915";
@wg-energy_laser_multidirectional_laser: "\e916";
@wg-energy_laser_ruhmkorffone: "\e917";
@wg-energy_plasma_arc_thrower: "\e918";
@wg-energy_plasma_gas_discharge: "\e919";
@wg-energy_plasma_heat_ray: "\e91a";
@wg-energy_plasma_ion_beam: "\e91b";
@wg-energy_plasma_lightning_blast: "\e91c";
@wg-energy_plasma_proton_beam: "\e91d";
@wg-explosive_attack: "\e91e";
@wg-explosive_bomb_cluster_grenades: "\e91f";
@wg-explosive_bomb_dynamite_blast: "\e920";
@wg-explosive_bomb_micro_black_hole_launcher: "\e921";
@wg-explosive_bomb_miranda_drakes_blaster: "\e922";
@wg-explosive_bomb_nitroglycerine: "\e923";
@wg-explosive_firebomb_I: "\e924";
@wg-explosive_fragmentation_grenade_I: "\e925";
@wg-explosive_heat_explosion: "\e926";
@wg-explosive_idle: "\e927";
@wg-explosive_rocket_antimatter_blast: "\e928";
@wg-explosive_rocket_fission_grenades: "\e929";
@wg-explosive_rocket_fusion_bomb: "\e92a";
@wg-explosive_rocket_interspace_missiles: "\e92b";
@wg-explosive_rocket_missile: "\e92c";
@wg-explosive_rocket_nuclear_warhead: "\e92d";
@wg-explosive_rocket_seeker_missile: "\e92e";
@wg-explosive_sticky_bomb_I: "\e92f";
@wg-generate_shields_I: "\e930";
@wg-generate_shields_II: "\e931";
@wg-key_debug: "\e932";
@wg-kinetic_attack: "\e933";
@wg-kinetic_barrage_I: "\e934";
@wg-kinetic_cannon_aidans_star_shooter: "\e935";
@wg-kinetic_cannon_asteroid_tractor: "\e936";
@wg-kinetic_cannon_black_powder_cannon: "\e937";
@wg-kinetic_cannon_drillhead: "\e938";
@wg-kinetic_cannon_haubitzer: "\e939";
@wg-kinetic_cannon_mortar: "\e93a";
@wg-kinetic_gun_gaussgun: "\e93b";
@wg-kinetic_gun_high_pressure_pneumatic_pistol: "\e93c";
@wg-kinetic_gun_railgun: "\e93d";
@wg-kinetic_gun_repeater: "\e93e";
@wg-kinetic_gun_subsurfaceexplosives: "\e93f";
@wg-kinetic_heated_bullets_I: "\e940";
@wg-kinetic_idle: "\e941";
@wg-kinetic_sniping_shot_I: "\e942";
@wg-overdrive_I: "\e943";
@wg-pirate_queens_map: "\e944";
@wg-scrap: "\e945";
@wg-shield_anti_magnetic_shield: "\e946";
@wg-shield_antigravity_field: "\e947";
@wg-shield_bioplating: "\e948";
@wg-shield_decoy_projector: "\e949";
@wg-shield_energy_projector: "\e94a";
@wg-shield_extra_sacrificial_plates: "\e94b";
@wg-shield_gamma_field: "\e94c";
@wg-shield_part: "\e94d";
@wg-shield_reflective_plates: "\e94e";
@wg-shield_self: "\e94f";
@wg-shield_whipple_shield: "\e950";
@wg-stasis_field: "\e951";
@wg-supercharge_I: "\e952";
@wg-uncertainty: "\e953";
@wg-upgrade_catalyst_t0: "\e954";
@wg-warpcrystal: "\e955";
@wg-weapon_part: "\e956";
@wg-acc_drive_blast_drive: "\e957";
@wg-acc_drive_extra_booster: "\e958";
@wg-acc_drive_hydrogen_rocket: "\e959";
@wg-acc_drive_recoil_drive: "\e95a";
@wg-acc_regen_antimatter_infuser: "\e95b";
@wg-acc_regen_field_rejuvenator: "\e95c";

