@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?7vd6fb') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?7vd6fb') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?7vd6fb#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.wg {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wg-acc_regen_flexible_plating {
  &:before {
    content: @wg-acc_regen_flexible_plating; 
  }
}
.wg-acc_regen_radiative_reconstructor {
  &:before {
    content: @wg-acc_regen_radiative_reconstructor; 
  }
}
.wg-acc_regen_recharge_battery {
  &:before {
    content: @wg-acc_regen_recharge_battery; 
  }
}
.wg-acc_regen_regenerator {
  &:before {
    content: @wg-acc_regen_regenerator; 
  }
}
.wg-accessory_part {
  &:before {
    content: @wg-accessory_part; 
  }
}
.wg-action_defend {
  &:before {
    content: @wg-action_defend; 
  }
}
.wg-action_skip {
  &:before {
    content: @wg-action_skip; 
  }
}
.wg-armor_part {
  &:before {
    content: @wg-armor_part; 
  }
}
.wg-artifact_fragment {
  &:before {
    content: @wg-artifact_fragment; 
  }
}
.wg-charge_boost_I {
  &:before {
    content: @wg-charge_boost_I; 
  }
}
.wg-charge_boost_II {
  &:before {
    content: @wg-charge_boost_II; 
  }
}
.wg-combination_catalyst_t0 {
  &:before {
    content: @wg-combination_catalyst_t0; 
  }
}
.wg-combination_catalyst_t1 {
  &:before {
    content: @wg-combination_catalyst_t1; 
  }
}
.wg-energy_attack {
  &:before {
    content: @wg-energy_attack; 
  }
}
.wg-energy_beam_sweep_I {
  &:before {
    content: @wg-energy_beam_sweep_I; 
  }
}
.wg-energy_concentrated_beam_I {
  &:before {
    content: @wg-energy_concentrated_beam_I; 
  }
}
.wg-energy_idle {
  &:before {
    content: @wg-energy_idle; 
  }
}
.wg-energy_incinerate_I {
  &:before {
    content: @wg-energy_incinerate_I; 
  }
}
.wg-energy_laser_bloodhunterpiercer {
  &:before {
    content: @wg-energy_laser_bloodhunterpiercer; 
  }
}
.wg-energy_laser_gamma_ray {
  &:before {
    content: @wg-energy_laser_gamma_ray; 
  }
}
.wg-energy_laser_gatling_laser {
  &:before {
    content: @wg-energy_laser_gatling_laser; 
  }
}
.wg-energy_laser_laser {
  &:before {
    content: @wg-energy_laser_laser; 
  }
}
.wg-energy_laser_multidirectional_laser {
  &:before {
    content: @wg-energy_laser_multidirectional_laser; 
  }
}
.wg-energy_laser_ruhmkorffone {
  &:before {
    content: @wg-energy_laser_ruhmkorffone; 
  }
}
.wg-energy_plasma_arc_thrower {
  &:before {
    content: @wg-energy_plasma_arc_thrower; 
  }
}
.wg-energy_plasma_gas_discharge {
  &:before {
    content: @wg-energy_plasma_gas_discharge; 
  }
}
.wg-energy_plasma_heat_ray {
  &:before {
    content: @wg-energy_plasma_heat_ray; 
  }
}
.wg-energy_plasma_ion_beam {
  &:before {
    content: @wg-energy_plasma_ion_beam; 
  }
}
.wg-energy_plasma_lightning_blast {
  &:before {
    content: @wg-energy_plasma_lightning_blast; 
  }
}
.wg-energy_plasma_proton_beam {
  &:before {
    content: @wg-energy_plasma_proton_beam; 
  }
}
.wg-explosive_attack {
  &:before {
    content: @wg-explosive_attack; 
  }
}
.wg-explosive_bomb_cluster_grenades {
  &:before {
    content: @wg-explosive_bomb_cluster_grenades; 
  }
}
.wg-explosive_bomb_dynamite_blast {
  &:before {
    content: @wg-explosive_bomb_dynamite_blast; 
  }
}
.wg-explosive_bomb_micro_black_hole_launcher {
  &:before {
    content: @wg-explosive_bomb_micro_black_hole_launcher; 
  }
}
.wg-explosive_bomb_miranda_drakes_blaster {
  &:before {
    content: @wg-explosive_bomb_miranda_drakes_blaster; 
  }
}
.wg-explosive_bomb_nitroglycerine {
  &:before {
    content: @wg-explosive_bomb_nitroglycerine; 
  }
}
.wg-explosive_firebomb_I {
  &:before {
    content: @wg-explosive_firebomb_I; 
  }
}
.wg-explosive_fragmentation_grenade_I {
  &:before {
    content: @wg-explosive_fragmentation_grenade_I; 
  }
}
.wg-explosive_heat_explosion {
  &:before {
    content: @wg-explosive_heat_explosion; 
  }
}
.wg-explosive_idle {
  &:before {
    content: @wg-explosive_idle; 
  }
}
.wg-explosive_rocket_antimatter_blast {
  &:before {
    content: @wg-explosive_rocket_antimatter_blast; 
  }
}
.wg-explosive_rocket_fission_grenades {
  &:before {
    content: @wg-explosive_rocket_fission_grenades; 
  }
}
.wg-explosive_rocket_fusion_bomb {
  &:before {
    content: @wg-explosive_rocket_fusion_bomb; 
  }
}
.wg-explosive_rocket_interspace_missiles {
  &:before {
    content: @wg-explosive_rocket_interspace_missiles; 
  }
}
.wg-explosive_rocket_missile {
  &:before {
    content: @wg-explosive_rocket_missile; 
  }
}
.wg-explosive_rocket_nuclear_warhead {
  &:before {
    content: @wg-explosive_rocket_nuclear_warhead; 
  }
}
.wg-explosive_rocket_seeker_missile {
  &:before {
    content: @wg-explosive_rocket_seeker_missile; 
  }
}
.wg-explosive_sticky_bomb_I {
  &:before {
    content: @wg-explosive_sticky_bomb_I; 
  }
}
.wg-generate_shields_I {
  &:before {
    content: @wg-generate_shields_I; 
  }
}
.wg-generate_shields_II {
  &:before {
    content: @wg-generate_shields_II; 
  }
}
.wg-key_debug {
  &:before {
    content: @wg-key_debug; 
  }
}
.wg-kinetic_attack {
  &:before {
    content: @wg-kinetic_attack; 
  }
}
.wg-kinetic_barrage_I {
  &:before {
    content: @wg-kinetic_barrage_I; 
  }
}
.wg-kinetic_cannon_aidans_star_shooter {
  &:before {
    content: @wg-kinetic_cannon_aidans_star_shooter; 
  }
}
.wg-kinetic_cannon_asteroid_tractor {
  &:before {
    content: @wg-kinetic_cannon_asteroid_tractor; 
  }
}
.wg-kinetic_cannon_black_powder_cannon {
  &:before {
    content: @wg-kinetic_cannon_black_powder_cannon; 
  }
}
.wg-kinetic_cannon_drillhead {
  &:before {
    content: @wg-kinetic_cannon_drillhead; 
  }
}
.wg-kinetic_cannon_haubitzer {
  &:before {
    content: @wg-kinetic_cannon_haubitzer; 
  }
}
.wg-kinetic_cannon_mortar {
  &:before {
    content: @wg-kinetic_cannon_mortar; 
  }
}
.wg-kinetic_gun_gaussgun {
  &:before {
    content: @wg-kinetic_gun_gaussgun; 
  }
}
.wg-kinetic_gun_high_pressure_pneumatic_pistol {
  &:before {
    content: @wg-kinetic_gun_high_pressure_pneumatic_pistol; 
  }
}
.wg-kinetic_gun_railgun {
  &:before {
    content: @wg-kinetic_gun_railgun; 
  }
}
.wg-kinetic_gun_repeater {
  &:before {
    content: @wg-kinetic_gun_repeater; 
  }
}
.wg-kinetic_gun_subsurfaceexplosives {
  &:before {
    content: @wg-kinetic_gun_subsurfaceexplosives; 
  }
}
.wg-kinetic_heated_bullets_I {
  &:before {
    content: @wg-kinetic_heated_bullets_I; 
  }
}
.wg-kinetic_idle {
  &:before {
    content: @wg-kinetic_idle; 
  }
}
.wg-kinetic_sniping_shot_I {
  &:before {
    content: @wg-kinetic_sniping_shot_I; 
  }
}
.wg-overdrive_I {
  &:before {
    content: @wg-overdrive_I; 
  }
}
.wg-pirate_queens_map {
  &:before {
    content: @wg-pirate_queens_map; 
  }
}
.wg-scrap {
  &:before {
    content: @wg-scrap; 
  }
}
.wg-shield_anti_magnetic_shield {
  &:before {
    content: @wg-shield_anti_magnetic_shield; 
  }
}
.wg-shield_antigravity_field {
  &:before {
    content: @wg-shield_antigravity_field; 
  }
}
.wg-shield_bioplating {
  &:before {
    content: @wg-shield_bioplating; 
  }
}
.wg-shield_decoy_projector {
  &:before {
    content: @wg-shield_decoy_projector; 
  }
}
.wg-shield_energy_projector {
  &:before {
    content: @wg-shield_energy_projector; 
  }
}
.wg-shield_extra_sacrificial_plates {
  &:before {
    content: @wg-shield_extra_sacrificial_plates; 
  }
}
.wg-shield_gamma_field {
  &:before {
    content: @wg-shield_gamma_field; 
  }
}
.wg-shield_part {
  &:before {
    content: @wg-shield_part; 
  }
}
.wg-shield_reflective_plates {
  &:before {
    content: @wg-shield_reflective_plates; 
  }
}
.wg-shield_self {
  &:before {
    content: @wg-shield_self; 
  }
}
.wg-shield_whipple_shield {
  &:before {
    content: @wg-shield_whipple_shield; 
  }
}
.wg-stasis_field {
  &:before {
    content: @wg-stasis_field; 
  }
}
.wg-supercharge_I {
  &:before {
    content: @wg-supercharge_I; 
  }
}
.wg-uncertainty {
  &:before {
    content: @wg-uncertainty; 
  }
}
.wg-upgrade_catalyst_t0 {
  &:before {
    content: @wg-upgrade_catalyst_t0; 
  }
}
.wg-warpcrystal {
  &:before {
    content: @wg-warpcrystal; 
  }
}
.wg-weapon_part {
  &:before {
    content: @wg-weapon_part; 
  }
}
.wg-acc_drive_blast_drive {
  &:before {
    content: @wg-acc_drive_blast_drive; 
  }
}
.wg-acc_drive_extra_booster {
  &:before {
    content: @wg-acc_drive_extra_booster; 
  }
}
.wg-acc_drive_hydrogen_rocket {
  &:before {
    content: @wg-acc_drive_hydrogen_rocket; 
  }
}
.wg-acc_drive_recoil_drive {
  &:before {
    content: @wg-acc_drive_recoil_drive; 
  }
}
.wg-acc_regen_antimatter_infuser {
  &:before {
    content: @wg-acc_regen_antimatter_infuser; 
  }
}
.wg-acc_regen_field_rejuvenator {
  &:before {
    content: @wg-acc_regen_field_rejuvenator; 
  }
}

