@import 'variables';
@import "buttons";

body {
  font-family: 'Space Mono', monospace;
  // text-transform: lowercase;
}

input {
  font-family: 'Space Mono', monospace;
  // text-transform: lowercase;
}

div, p {
  user-select: none;
}

.text-bold {
  font-weight: bold;
}

.color-variants(@name, @color) {
  .text-@{name} {
    color: @color !important;
  }
  .background-@{name} {
    background-color: @color !important;
  }
  .border-@{name} {
    border: 1px solid @color;
  }
}

.color-variants(fallback, @color-rarity-fallback);
.color-variants(common, @color-rarity-common);
.color-variants(uncommon, @color-rarity-uncommon);
.color-variants(rare, @color-rarity-rare);
.color-variants(legendary, @color-rarity-legendary);

.color-variants(damage-type-kinetic, @color-damage-type-kinetic);
.color-variants(damage-type-energy, @color-damage-type-energy);
.color-variants(damage-type-explosive, @color-damage-type-explosive);

.color-variants(shield, @color-shield);

// Content-Elements

.wg:before {
  content: "\e404";
}

@color-low: @color-rarity-uncommon;
@color-mid: #c6ce3f;
@color-high: #7B1FA2;
@color-max: @color-rarity-relic;

.upgrade-border {

  &.upgrade-1 {
    box-shadow: inset -2px -2px 5px 0 @color-low;
  }

  &.upgrade-2 {
    box-shadow: inset -3px -3px 5px 0 @color-low;
  }

  &.upgrade-3 {
    box-shadow: inset -4px -4px 5px 0 @color-low;
  }

  &.upgrade-4 {
    box-shadow: inset -2px -2px 4px 0 @color-mid;
  }

  &.upgrade-5 {
    box-shadow: inset -3px -3px 5px 0 @color-mid;
  }

  &.upgrade-6 {
    box-shadow: inset -4px -4px 5px 0 @color-mid;
  }

  &.upgrade-7 {
    box-shadow: inset -2px -2px 5px 0 @color-high;
  }

  &.upgrade-8 {
    box-shadow: inset -3px -3px 5px 0 @color-high;
  }

  &.upgrade-9 {
    box-shadow: inset -4px -4px 5px 0 @color-high;
  }

  &.upgrade-10 {
    box-shadow: inset -2px -2px 5px 0 @color-max;
  }
}

.upgrade-full-border {

  &.upgrade-0 {
    // box-shadow: 0 0 0 1px @color-common;
  }

  &.upgrade-1 {
    box-shadow: 0 0 10px 3px @color-low;
  }

  &.upgrade-2 {
    box-shadow: 0 0 10px 3px @color-low;
  }

  &.upgrade-3 {
    box-shadow: 0 0 10px 3px @color-low;
  }

  &.upgrade-4 {
    box-shadow: 0 0 10px 3px @color-mid;
  }

  &.upgrade-5 {
    box-shadow: 0 0 10px 3px @color-mid;
  }

  &.upgrade-6 {
    box-shadow: 0 0 10px 3px @color-mid;
  }

  &.upgrade-7 {
    box-shadow: 0 0 10px 3px @color-high;
  }

  &.upgrade-8 {
    box-shadow: 0 0 10px 3px @color-high;
  }

  &.upgrade-9 {
    box-shadow: 0 0 10px 3px @color-high;
  }

  &.upgrade-10 {
    box-shadow: 0 0 10px 3px @color-max;
  }
}

@import '../assets/style';
